import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import { InfoCard } from '@rsa-digital/evo-shared-components/components/InfoCard';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const BannerGridItem = styled(GridItem)`
  text-align: center;
  background-color: ${colors.core02};

  ${mediaQuery.tabletPortrait`
    text-align: left;
  `}
`;

export const Heading = styled.p`
  ${fonts.headingXSmall};
  padding: ${spacing(2)};
  margin: 0;
  background-color: ${colors.notificationInfoLight};
  text-align: center;
`;

export const ClaimsCoveredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: ${spacing(3)} ${spacing(3)} 0;

  ${mediaQuery.tabletLandscape`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${spacing(5)} ${spacing(5)} 0;
  `}
`;

export const BannerTextGridItem = styled(GridItem)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${colors.neutral03};
  padding: ${spacing(3)};
  margin: 0;
  min-width: 190px;
  width: 100%;

  ${mediaQuery.tabletLandscape`
    align-items: start;
    width: 60%;
    padding: ${spacing(4)} ${spacing(8.5)} ${spacing(4)} ${spacing(4)};
  `};

  ${mediaQuery.desktop`
    width: 64%;
  `}
`;

export const DonutChartGridItem = styled(GridItem)`
  display: flex;
  justify-content: center;
  margin: ${spacing(5)} 0 0;
  width: 100%;

  ${mediaQuery.tabletLandscape`
    justify-content: end;
    margin-top: 0;
    width: 35%;
  `}

  ${mediaQuery.desktop`
    width: 30%;
  `}
`;

export const BorderGridItem = styled(GridItem)`
  display: flex;
  border: 1px solid ${colors.neutral03};
  flex-direction: column;

  ${mediaQuery.tabletLandscape`
   
  `}
`;

export const GridWithMargins = styled(Grid)`
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(4)};
  `}
`;

export const RichTextWithModalAndMargin = styled(RichTextWithModal)`
  margin: ${spacing(5)} ${spacing(3)} 0;

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}
`;

export const MonthlyPaymentPromotionInfoPanelDesktop = styled(InfoCard)`
  border: 0;
  display: none;
  padding: 0;
  margin: 24px;

  ${mediaQuery.tabletPortrait`
    display: block;
    margin: 32px;
  `}
  h3 {
    font-size: 1.25rem;
    margin: ${spacing(2)} 0 0;
  }
`;

export const MonthlyPaymentPromotionInfoPanelMobile = styled(InfoCard)`
  border: 0;
  border-bottom: 1px solid ${colors.neutral03};
  padding: 0 0 ${spacing(4)};
  text-align: left;

  ${mediaQuery.tabletPortrait`
    display: none;
  `}
  h3 {
    font-size: 1.25rem;
    margin: ${spacing(2)} 0 0;
  }
`;

export const DividerWithMargins = styled(Divider)`
  display: none;
  margin: 0;

  ${mediaQuery.tabletPortrait`
    display: block;
  `}
`;

export const InfoPanelGridItem = styled(GridItem)`
  margin: 0;
  width: 100%;
`;

export const QualityRatingsSection = styled.div`
  margin-top: ${spacing(3)};
  border-bottom: 1px solid ${colors.neutral03};
  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(7)}
  `};
`;

export const QualityRatingContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.neutral03};
  ${mediaQuery.tabletLandscape`
    flex-direction: row;
  `};
`;

export const QualityRatingImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    align-items: center;
    margin: auto 0 auto ${spacing(5)};
  `};
`;

export const QualityRatingSizedImage = styled(Image)`
  flex-shrink: 0;
  margin-bottom: 0;
  width: ${spacing(11)};
`;

export const QualityRatingTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${spacing(3)};
  text-align: center;
  ${mediaQuery.tabletLandscape`
    margin-right: ${spacing(4)};
    text-align: left;
  `};
`;

export const QualityRatingHeading = styled.p`
  ${fonts.headingXSmall};
  margin: 0;
  & {
    line-height: 2rem;
  }
`;

export const QualityRatingText = styled.p`
  ${fonts.paragraphLarge}
  margin: ${spacing(1)} 0 0;
`;

export const WhichBestBuySizedImage = styled(Image)`
  flex-shrink: 0;
  margin-bottom: 0;
  width: ${spacing(11)};
`;
